import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import '../styles/theme.scss';
import { FaFacebookF, FaGithub, FaLinkedinIn, FaBehance } from 'react-icons/fa';
import headerStyles from '../styles/header.module.scss';

const Header = () => (
  <header className={headerStyles.header}>
    <div className={headerStyles.brandContainer}>
      <div className="ellipses-container">
        <div className="ellipses ellipses__outer--thin">
          <div className="ellipses ellipses__orbit" />
        </div>
        <div className="ellipses ellipses__outer--thick" />
      </div>
    </div>
    <div className={headerStyles.navContainer}>
      <nav>
        <ul className={headerStyles.navList}>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/"
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/school-projects/"
            >
              SCHOOL PROJECTS
            </Link>
          </li>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/real-projects/"
            >
              REAL WORLD PROJECTS
            </Link>
          </li>
          {/* <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blogs/">BLOGS</Link></li> */}
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/about/"
            >
              ABOUT
            </Link>
          </li>
        </ul>
      </nav>
    </div>
    <div className={headerStyles.socialContainer}>
      <ul className={headerStyles.socialList}>
        <li>
          <a
            className="facebook"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
        </li>
        <li>
          <a
            className="linkedin"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </li>
        <li>
          <a
            className="behance"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaBehance />
          </a>
        </li>
        <li>
          <a
            className="github"
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </li>
      </ul>
    </div>
  </header>
);
Header.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  siteTitle: PropTypes.string,
};
Header.defaultProps = {
  siteTitle: '',
};
export default Header;
